import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import moment from "moment";
import { SnagModel } from "../../../types/api";

interface ReportPageProps {
  report: any;
}

const styles = StyleSheet.create({
  roomAndTypeView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: 13,
    marginVertical: 10,
  },
  sectionName: {
    fontFamily: "CeraPro",
    color: "#17171C",
    fontSize: 18
  },
  subSectionName: {
    fontFamily: "CeraProRegular",
    fontSize: 14,
    color: "#E28E45",
  },
  contentSection: {
    marginVertical: 3,
  },
  contentSectionName: {
    color: "#929292",
    fontSize: 13,
  },
  contentSectionValue: {
    fontSize: 13,
    color: "#454444",
    marginVertical: 4,
  },
  reportImagesView: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  sectionHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginVertical: 10,
    backgroundColor: "white",
    padding: 10,
    borderRadius: 10,
  },
  floorTitleViewTotal: {
    borderWidth: 1,
    fontSize: 10,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 6,
  },
  pageNumber: {
    position: 'absolute',
    bottom: 10,
    right: 30,
    fontSize: 12,
    fontFamily: 'CeraProRegular',
  },
});

const imageWidth = 586 / 3 - 10 - 80 / 3;
const ReportPage: React.FC<ReportPageProps> = ({ report }) => {
  return (
    <Page size="A4" style={{ backgroundColor: "#ffffff", paddingVertical: 40 }}>
      {
        report?.snagList.map((snag: any) => {
          return (
            <View key={snag.id}>
              <View key={snag.id} style={{ paddingBottom: '15px', paddingHorizontal: 40 }}>
                <View wrap={false}  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: 20 }}>
                  <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', flex: 2  }}>
                    <Text style={{ ...styles.subSectionName }}>
                      {snag.snag_location.room.title} • {snag.snag_location.title} • {snag.issue_type}&nbsp;
                    </Text>
                    {
                      snag.status === 'resolved' ? (
                        <Text style={{ color: '#00FF00', fontFamily: "CeraProRegular", fontSize: 14 }}>
                          • [CLOSED]
                        </Text>
                      ) : (
                        <Text style={{ color: '#FF0000', fontFamily: "CeraProRegular", fontSize: 14 }}>
                          • [OPEN]
                        </Text>
                      )
                    }
                  </View>
                  <View style={{ flex: .2 }}>
                    <View style={{ border: '2px solid #45505E', borderRadius: '4px', height: '15', width: '15' }} ></View>
                  </View>
                </View>
                
                <View wrap={false}  style={{ borderBottom: '1px solid #c8ccc9', width: '100%', marginTop: '10px', marginBottom: '10px' }} />

                <View wrap={false}  style={{ flexDirection: 'row', display: 'flex', marginTop: '10px' }}>
                  <Text style={{ fontSize: 12, color: '#798286' }}> Last Updated At: </Text>
                  <Text style={{ fontSize: 12, color: '#45505E' }}> {moment(snag.updated_at).format('DD/MM/YYYY HH:mm:ss')} </Text>
                </View>

                <View wrap={false}  style={{ flexDirection: 'row', display: 'flex', marginTop: '10px' }}>
                  <Text style={{ fontSize: 12, color: '#798286' }}> Description: </Text>
                  <Text style={{ fontSize: 12, color: '#45505E' }}> {snag.details} </Text>
                </View>
                
                {/* IMAGES */}
                <View wrap={false}> 
                  <ImageSection snag={snag} /> 
                </View>
                
                {/* TRADES */}
                <View wrap={false}  style={{ flexDirection: 'column', display: 'flex', marginTop: '10px' }}>
                  <Text style={{ fontSize: 12, color: '#798286' }}> Assigned Trade: </Text>
                  {
                    snag?.snag_trades?.map((snagTrade: any, index: number) => {
                      return (
                        <View key={snagTrade.id} style={{ flexDirection: 'row', display: 'flex', marginTop: 10 }}>
                          <Text style={{ fontSize: 12, color: 'black' }}> {snagTrade.trade.trade.title} •</Text>
                          <Text style={{ fontSize: 12, color: 'black' }}> {snagTrade.trade.company_name} </Text>
                          <Text style={{ fontSize: 12, color: '#798286' }}> / {snagTrade.trade.name} </Text>
                          <Text style={{ fontSize: 12, color: '#798286' }}> [{snagTrade.trade.email}] </Text>
                        </View>
                      )
                    })
                  }
                </View>
                
                {/* COMMENTS */}
                {/* <View wrap={false} style={{ marginTop: '20px' }}>
                  <Text style={{ fontSize: 12, color: '#798286' }} >Comments:</Text>
                  <View style={{ borderBottom: '1px solid #c8ccc9', width: '100%', marginTop: '2px', marginBottom: '10px' }} />
                  <View style={{ borderBottom: '1px solid #c8ccc9', width: '100%', marginTop: '20px', marginBottom: '10px' }} />
                  <View style={{ borderBottom: '1px solid #c8ccc9', width: '100%', marginTop: '20px', marginBottom: '10px' }} />
                </View> */}
              </View>
              <View style={{ borderBottom: '2px solid black', width: '100%', marginTop: '15px', marginBottom: '25px' }} />
            </View>
          )
        })
      }
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `Page ${pageNumber} of ${totalPages}`
      )} fixed />
    </Page>
  );
};

export default ReportPage;

const ImageSection = ({ snag }: { snag: SnagModel}) => {
  return (
    <View style={styles.reportImagesView}>
      {snag.file_1 && (
        <View style={{ paddingBottom: 10, paddingTop: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
          <Image
            src={snag.file_1}
            style={{
              borderRadius: '5px',
              width: imageWidth,
              height: imageWidth,
              objectFit: "cover",
              margin: 4,
            }}
          />
        </View>
      )}
      {snag.file_2 && (
        <View style={{ paddingBottom: 10, paddingTop: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
          <Image
            src={snag.file_2}
            style={{
              borderRadius: '5px',
              width: imageWidth,
              height: imageWidth,
              objectFit: "cover",
              margin: 4,
            }}
          />
        </View>
      )}
      {snag.file_3 && (
        <View style={{ paddingBottom: 10, paddingTop: 10, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
          <Image
            src={snag.file_3}
            style={{
              borderRadius: '5px',
              width: imageWidth,
              height: imageWidth,
              objectFit: "cover",
              margin: 4,
            }}
          />
        </View>
      )}
    </View>
  );
};
